import { FOLLOW_UP_BOSS, INTEGRATION_TYPES } from '@forwardflow/types'

export const QUERY_KEYS = {
   AUTH_USER: 'AUTH_USER',
   REDIRECT_TOKEN: 'REDIRECT_TOKEN',
   CAMPAIGN_HIERARCHY: 'CAMPAIGN_HIERARCHY',
   CAMPAIGN_SUMMARIZED_HIERARCHY: 'CAMPAIGN_SUMMARIZED_HIERARCHY',
   CRM_INTEGRATIONS: 'CRM_INTEGRATIONS',
   PAYMENT_METHODS: 'PAYMENT_METHODS',
   USER_ACCOUNT: 'USER_ACCOUNT',
   VALIDATE_EMAIL: 'VALIDATE_EMAIL',
   PHONE_NUMBERS: 'PHONE_NUMBERS',
   SUBSCRIBED_PACKAGE: 'SUBSCRIBED_PACKAGE',
   SUBSCRIPTION_PACKAGES: 'SUBSCRIPTION_PACKAGES',
   ORGANIZATION_USERS: 'ORGANIZATION_USERS',
   ONBOARDING_PROGRESS: 'ONBOARDING_PROGRESS',
   BUSINESS_DETAILS: 'BUSINESS_DETAILS',
   ANALYTICS_FUB_LOST_OPPORTUNITIES: 'ANALYTICS_FUB_LOST_OPPORTUNITIES',
   ANALYTICS_AVG_TIME_TAKEN_FOR_FIRST_CALL:
      'ANALYTICS_AVG_TIME_TAKEN_FOR_FIRST_CALL',
   ANALYTICS_STAGES_INFORMATION: 'ANALYTICS_STAGES_INFORMATION',
   ANALYTICS_INCOMPLETE_INFORMATION: 'ANALYTICS_INCOMPLETE_INFORMATION',
   BUSINESS_HOURS: 'BUSINESS_HOURS',
   SUBSCRIPTION_BILLING_HISTORY: 'SUBSCRIPTION_BILLING_HISTORY',
   STAGE_GROUP_CONFIG: 'STAGE_GROUP_CONFIG',
   STAGE_GROUP_RECOMMEND_CONFIG: 'STAGE_GROUP_RECOMMEND_CONFIG',
   GET_lEAD_STAGES: 'GET_LEAD_STAGES',
   GET_TAGS: 'GET_TAGS',
   CAMPAIGN_BUSINESS_HOURS: 'CAMPAIGN_BUSINESS_HOURS',
   CAMPAIGN_USP: 'CAMPAIGN_USP',
   CAMPAIGN_QUALIFYING_QUESTIONS: 'CAMPAIGN_QUALIFYING_QUESTIONS',
   ORGANIZATION_CAMPAIGNS: 'ORGANIZATION_CAMPAIGNS',
   GET_CONTACT_FILTER_OPTIONS: 'GET_CONTACT_FILTER_OPTIONS',
   ALL_CAMPAIGNS: 'ALL_CAMPAIGNS',
   CAMPAIGN_DETAILS_BY_ID: 'CAMPAIGN_DETAILS_BY_ID',
   CAMPAIGN_OVERVIEW_BY_ID: 'CAMPAIGN_OVERVIEW_BY_ID',
   CAMPAIGN_PHONE_NUMBERS: 'CAMPAIGN_PHONE_NUMBERS',
   CAMPAIGN_CONFIG: 'CAMPAIGN_CONFIG',
   DASHBOARD_DATA: 'DASHBOARD_DATA',
   CAMPAIGN_ASSISTANTS: 'CAMPAIGN_ASSISTANTS',
   CAMPAIGNS_DATA: 'CAMPAIGNS_DATA',
   CAMPAIGNS_OVERVIEW: 'CAMPAIGNS_OVERVIEW',
   CAMPAIGN_ACTIVATION_SUMMARY: 'CAMPAIGN_ACTIVATION_SUMMARY',
   CAMPAIGN_ACTIVATION_VOICES: 'CAMPAIGN_ACTIVATION_VOICES',
   ORGANIZATION_CAMPAIGN_DETAILS_BY_ID: 'ORGANIZATION_CAMPAIGN_DETAILS_BY_ID',
   ORGANIZATION_CAMPAIGNS_DETAILS: 'ORGANIZATION_CAMPAIGNS_DETAILS',
}

export const CRM_INTEGRATIONS: {
   type: INTEGRATION_TYPES
   title: string
   subTitle1: string
   subTitle2: string
}[] = [
   {
      type: FOLLOW_UP_BOSS,
      title: 'Follow Up Boss',
      subTitle1: 'Create leads from Forward Flow Calls',
      subTitle2: 'Trigger calls in Forward Flow',
   },
]
