import './App.scss'
import { ENV } from '@forwardflow/helpers/configs'
import AppAnalytics from './hooks/customHooks/appAnalytics'
import Routing from './router/Router'
import {
   ConfigCatProvider,
   createConsoleLogger,
   LogLevel,
} from 'configcat-react'

function App() {
   const logger = createConsoleLogger(LogLevel.Info)

   return (
      <ConfigCatProvider sdkKey={ENV.CONFIGCAT_SDK_KEY} options={{ logger }}>
         <main className="min-h-screen">
            <AppAnalytics />
            <Routing></Routing>
         </main>
      </ConfigCatProvider>
   )
}

export default App
